import React from "react";

const CreateAccount = () => {
  return (
    <div class="container-fluid p-5 mt-5">
      <div class="row mb-5 ml-5">
        <div class="col-xl-10 col-lg-9 col-md-8 ml-auto">
          <div class="row align-item-center">
            <div class="col-xl-10 col-12 mb-4 mb-xl-0">
              <div class="card">
                <div class="card-header">
                  <strong>Create Account Form</strong>{" "}
                </div>

                <form method="post">
                  <div class="card-body card-block">
                    <div class="form-group">
                      <label for="categories" class=" form-control-label">
                        Customer Name
                      </label>

                      <input
                        type="text"
                        name="categories"
                        placeholder="Enter customer name"
                        class="form-control"
                        required
                        value=""
                      />
                    </div>
                    <div class="form-group">
                      <label for="categories" class=" form-control-label">
                        Customer Address
                      </label>

                      <input
                        type="text"
                        name="categories"
                        placeholder="Enter customer address"
                        class="form-control"
                        required
                        value=""
                      />
                    </div>

                    <div class="form-group">
                      <label for="categories" class=" form-control-label">
                        Date of Birth
                      </label>

                      <input
                        type="text"
                        name="categories"
                        placeholder="Enter date of birth"
                        class="form-control"
                        required
                        value=""
                      />
                    </div>

                    <div class="form-group">
                      <label for="categories" class=" form-control-label">
                        Email Address
                      </label>

                      <input
                        type="text"
                        name="categories"
                        placeholder="Enter email address"
                        class="form-control"
                        required
                        value=""
                      />
                    </div>
                    <div class="form-group">
                      <label for="categories" class=" form-control-label">
                        Phone Number
                      </label>

                      <input
                        type="text"
                        name="categories"
                        placeholder="Enter phone number"
                        class="form-control"
                        required
                        value=""
                      />
                    </div>
                    <div class="form-group">
                      <label for="categories" class=" form-control-label">
                        Account Type
                      </label>

                      <input
                        type="text"
                        name="categories"
                        placeholder="Enter account Type"
                        class="form-control"
                        required
                        value=""
                      />
                    </div>
                    <div class="form-group">
                      <label for="categories" class=" form-control-label">
                        Enter Branch
                      </label>

                      <input
                        type="text"
                        name="categories"
                        placeholder="Enter branch"
                        class="form-control"
                        required
                        value=""
                      />
                    </div>
                    <div class="form-group">
                      <label for="categories" class=" form-control-label">
                        Starting Balance
                      </label>

                      <input
                        type="text"
                        name="categories"
                        placeholder="Enter starting balance"
                        class="form-control"
                        required
                        value=""
                      />
                    </div>
                    <button
                      name="submit"
                      type="submit"
                      class="btn btn-lg btn-info btn-block"
                    >
                      <span>Submit</span>
                    </button>

                    <div
                      class="field_error"
                      style={{ color: "red", marginTop: "15px" }}
                    ></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
