import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginForm from "../shared/LoginForm";

const LoginCustomers = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  function login() {
    // axios
    //   .post("/customer", {
    //     username: username,
    //     user_password: password,
    //   })
    //   .then(function (response) {
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
    if (username === "ruxana" && password === "password")
      navigate("/customer-dashboard");
  }

  return (
    <div>
      <LoginForm
        type="customer"
        username={username}
        setUsername={setUsername}
        password={password}
        setPassword={setPassword}
        onclick={login}
      />
    </div>
  );
};

export default LoginCustomers;
