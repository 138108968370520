import React from "react";

const SignoutModal = () => {
  return (
    <div className="modal fade" id="sign-out">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Want to Leave?</h4>
            <button type="button" className="close" data-dismiss="modal">
              &times;
            </button>
          </div>
          <div className="modal-body">Press logout to leave...</div>
          <div className="modal-footer">
            <a href="customerIndex.html">
              {" "}
              <button
                type="button"
                className="btn btn-success"
                data-disiss="modal"
              >
                Stay Here
              </button>{" "}
            </a>
            <a href="../index.html">
              {" "}
              <button
                type="button"
                className="btn btn-danger"
                data-disiss="modal"
              >
                Logout
              </button>{" "}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignoutModal;
