import React from "react";

const CustomerIndex = () => {
  return (
    <div className="container-fluid p-5 mt-5">
      <div className="row mb-5 ml-5">
        <div className="col-xl-10 col-lg-9 col-md-8 ml-auto">
          <div className="row align-item-center">
            <div className="col-xl-10 col-12 mb-4 mb-xl-0">
              <h2 className="text-center mb-3 text-dark">YOUR DETAILS</h2>

              <table className="table bg-light text-center">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Account Number</th>
                    <th>Name</th>
                    <th>Address</th>
                    <th>Branch</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {[0, 1, 2, 3, 4, 5, 6, 7]?.map((e) => {
                    return (
                      <tr>
                        <td>{e}</td>
                        <td>25701736</td>
                        <td>abc </td>
                        <td>xyz </td>
                        <td>gajdy</td>
                        <td>{e * Math.floor(Math.random() * 100)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerIndex;
