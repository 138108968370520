import React from "react";
import { Link, Outlet } from "react-router-dom";

const EmployeeIndex = () => {
  return (
    <>
      <div className="container-fluid p-5 mt-5">
        <div className="row mb-5 ml-5">
          <div className="col-xl-10 col-lg-9 col-md-8 ml-auto">
            <div className="row align-item-center">
              <div className="col-xl-10 col-12 mb-4 mb-xl-10">
                <h2 className="text-center mb-3 text-dark">CUSTOMERS</h2>
                <h6 className="text-right mb-3 text-underline">
                  <Link to="add-customer">ADD CUSTOMER</Link>
                </h6>
                <h6 className="text-right mb-3 text-underline">
                  <Link to="create-account">CREATE ACCOUNT</Link>
                </h6>
                <table className="table bg-light text-center ">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Account Number</th>
                      <th>Username</th>
                      <th>Password</th>
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((e) => {
                      return (
                        <tr>
                          <td>{e}</td>
                          <td>1369836439</td>
                          <td>test {e}</td>
                          <td>test passsword {e}</td>
                          <td>
                            <span className="badge badge-warning w-30 py-2">
                              <a
                                style={{ color: "#fff" }}
                                href="manage_customer.html"
                              >
                                Edit
                              </a>
                            </span>
                            &nbsp;
                            <span className="badge badge-danger w-30 py-2">
                              <a style={{ color: "#fff" }} href="">
                                Delete
                              </a>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default EmployeeIndex;
