import React from "react";

const SideBarWrapper = ({ children }) => {
  return (
    <div className="col-xl-2 col-lg-3 col-md-4 sidebar fixed-top">
      <a
        href="/"
        className="navbar-brand text-white d-block mx-auto text-center py-3 mb-4  bottom-border"
      >
        <img src="/assets/logo.png" width="225px" alt="ABC Bank" />
      </a>
      <ul className="navbar-nav flex-column mt-4">{children}</ul>
    </div>
  );
};

export default SideBarWrapper;
