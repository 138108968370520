import React from "react";

const LoginWrapper = ({ children }) => {
  return (
    <div className="bg-dark">
      <div className="sufee-login d-flex align-content-center flex-wrap">
        <div className="container">
          <div className="login-content">
            <div className="login-form mt-150">
              <a
                href="/"
                className="navbar-brand text-white d-block mx-auto text-center "
              >
                <img src="assets/logo.png" alt="ABC Bank" width="225px" />
              </a>
              {children}
              <div
                className="field_error"
                style={{ color: "red", marginTop: "15px" }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginWrapper;
