import "./App.css";
import LoginCustomers from "./components/customers/LoginCustomers";
import LoginEmployee from "./components/employee/LoginEmployee";
import { Routes, Route } from "react-router-dom";
import HomeLogin from "./components/HomeLogin";

import DashboardWrapper from "./components/wrapper/DashboardWrapper";
import EmployeeDashboard from "./components/employee/EmployeeDashboard";
import CustomerDashboard from "./components/customers/CustomerDashboard";
import CustomerIndex from "./components/customers/CustomerIndex";
import Deposit from "./components/customers/Deposit";
import Transfer from "./components/customers/Transfer";
import Withdraw from "./components/customers/Withdraw";
import EmployeeIndex from "./components/employee/EmployeeIndex";
import Employee from "./components/employee/Employee";
import DepositAdmin from "./components/employee/DepositAdmin";
import Branch from "./components/employee/Branch";
import CreateAccount from "./components/employee/CreateAccount";
import ManageCustomer from "./components/employee/ManageCustomer";
import ManageEmployee from "./components/employee/ManageEmployee";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomeLogin />} />
      <Route path="/login-as-costomer" element={<LoginCustomers />} />
      <Route path="/login-as-employee" element={<LoginEmployee />} />
      <Route path="/employee-dashboard" element={<EmployeeDashboard />}>
        <Route path="employee-index" element={<EmployeeIndex />}></Route>
        <Route
          path="employee-index/create-account"
          element={<CreateAccount />}
        />
        <Route
          path="employee-index/add-customer"
          element={<ManageCustomer />}
        />
        <Route path="branch" element={<Branch />} />
        <Route path="employee" element={<Employee />}></Route>
        <Route path="branch/add-employee" element={<ManageEmployee />} />
        <Route path="deposit" element={<DepositAdmin />} />
        <Route path="withdraw" element={<Withdraw />} />
        <Route path="transfer" element={<Transfer />} />
      </Route>
      <Route path="/customer-dashboard" element={<CustomerDashboard />}>
        <Route path="customer" element={<CustomerIndex />} />
        <Route path="deposit" element={<Deposit />} />
        <Route path="transfer" element={<Transfer />} />
        <Route path="withdraw" element={<Withdraw />} />
      </Route>
      <Route path="/test" element={<DashboardWrapper />} />
    </Routes>
  );
}

export default App;
