import React from "react";

const ManageEmployee = () => {
  return (
    <div className="container-fluid p-5 mt-5">
      <div className="row mb-5 ml-5">
        <div className="col-xl-10 col-lg-9 col-md-8 ml-auto">
          <div className="row align-item-center">
            <div className="col-xl-10 col-12 mb-4 mb-xl-0">
              <div className="card">
                <div className="card-header">
                  <strong>Employee Form</strong>{" "}
                </div>

                <form method="post">
                  <div className="card-body card-block">
                    <div className="form-group">
                      <label
                        htmlFor="categories"
                        className=" form-control-label"
                      >
                        Employee Name
                      </label>

                      <input
                        type="text"
                        name="categories"
                        placeholder="Enter employee name"
                        className="form-control"
                        required
                        value=""
                      />
                    </div>

                    <div className="form-group">
                      <label
                        htmlFor="categories"
                        className=" form-control-label"
                      >
                        Employee Password
                      </label>

                      <input
                        type="text"
                        name="categories"
                        placeholder="Enter Employee password"
                        className="form-control"
                        required
                        value=""
                      />
                    </div>

                    <button
                      name="submit"
                      type="submit"
                      className="btn btn-lg btn-info btn-block"
                    >
                      <span>Submit</span>
                    </button>

                    <div
                      className="field_error"
                      style={{ color: "red", marginTop: "15px" }}
                    ></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageEmployee;
