import React from "react";

const DashboardMainTitle = (prop) => {
  return (
    <div className="col-md-4">
      <h4 className="text-light text-uppercase mb-0">Welcome {prop.title}</h4>
    </div>
  );
};

export default DashboardMainTitle;
