import React from "react";

const Signout = () => {
  return (
    <div className="col-md-3">
      <ul className="navbar-nav">
        <li className="nav-item ml-md-auto">
          <a
            href="#sign-out"
            className="nav-link"
            data-toggle="modal"
            data-target="#sign-out"
          >
            <i className="fas fa-sign-out-alt text-danger fa-lg"></i>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Signout;
