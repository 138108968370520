import React from "react";

const InputField = (prop) => {
  const inputChangeHandler = (e) => {
    prop.setInputText(e.target.value);
  };

  return (
    <div className="from-group mb-4">
      <label>{prop.label}</label>
      <input
        type={prop.type}
        name={prop.label}
        className="form-control"
        placeholder={prop.label}
        value={prop.inputText}
        onChange={inputChangeHandler}
        required
      />
    </div>
  );
};

export default InputField;
