import React from "react";

const TopNavbarWrapper = ({ children }) => {
  return (
    <div className="col-xl-10 col-lg-9 col-md-8 ml-auto bg-dark fixed-top py-2 top-navbar">
      <div className="row align-items-center">{children}</div>
    </div>
  );
};

export default TopNavbarWrapper;
