import React from "react";
import { Link } from "react-router-dom";
import LoginWrapper from "./wrapper/LoginWrapper";

const HomeLogin = () => {
  return (
    <LoginWrapper>
      <b>
        <u>
          <h4>WHAT DO YOU WANT TO LOGIN AS?</h4>
        </u>
      </b>
      <div className="parent d-flex justify-content-between">
        <div>
          <Link to="/login-as-employee" className="d-flex">
            <img src="assets/sample.jpeg" alt="" width="20px" />
            <h6 className="ms-1">EMPLOYEE</h6>
          </Link>
        </div>

        <div>
          <Link to="/login-as-costomer" className="d-flex">
            <img src="assets/sample.jpeg" alt="" width="20px" />
            <h6 className="ms-1">CUSTOMER</h6>
          </Link>
        </div>
      </div>
    </LoginWrapper>
  );
};

export default HomeLogin;
