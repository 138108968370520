import React from "react";
import InputField from "./InputField";
import LoginWrapper from "../wrapper/LoginWrapper";

const LoginForm = (prop) => {
  return (
    <LoginWrapper>
      <center>
        <b>
          <u>
            <h4>SIGN-IN as {prop.type}</h4>
          </u>
        </b>
      </center>
      <form>
        <InputField
          label="username"
          type="text"
          inputText={prop.username}
          setInputText={prop.setUsername}
        />
        <InputField
          label="password"
          type="password"
          inputText={prop.password}
          setInputText={prop.setPassword}
        />
        <button
          onClick={prop.onclick}
          className="btn btn-success btn-flat m-b-30 m-t-30"
        >
          Sign in
        </button>
      </form>
    </LoginWrapper>
  );
};

export default LoginForm;
