import React from "react";

const Transfer = () => {
  return (
    <div className="container-fluid p-5 mt-5">
      <div className="row mb-5 ml-5">
        <div className="col-xl-10 col-lg-9 col-md-8 ml-auto">
          <div className="row align-item-center">
            <div className="col-xl-10 col-12 mb-4 mb-xl-0">
              <h2 className="text-center mb-3 text-dark">TRANSFER</h2>

              <div className="card">
                <div className="card-header">
                  <strong>Transfer Form</strong>{" "}
                </div>

                <form method="post">
                  <div className="card-body card-block">
                    <div className="form-group">
                      <label
                        htmlFor="categories"
                        className=" form-control-label"
                      >
                        Transfer Amount
                      </label>

                      <input
                        type="text"
                        name="categories"
                        placeholder="Enter transfer amount"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group">
                      <label
                        htmlFor="categories"
                        className=" form-control-label"
                      >
                        Account to Transfer to
                      </label>

                      <input
                        type="text"
                        name="categories"
                        placeholder="Enter transfer account"
                        className="form-control"
                      />
                    </div>

                    <button
                      name="submit"
                      type="submit"
                      className="btn btn-lg btn-info btn-block"
                    >
                      <span>Submit</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transfer;
