import React from "react";
import { Link, Outlet } from "react-router-dom";
import DashboardMainTitle from "../shared/DashboardMainTitle";
import Signout from "../shared/Signout";
import SignoutModal from "../shared/SignoutModal";
import DashboardWrapper from "../wrapper/DashboardWrapper";
import SideBarWrapper from "../wrapper/SideBarWrapper";
import TopNavbarWrapper from "../wrapper/TopNavbarWrapper";
// import CustomerIndex from "./CustomerIndex";

const CustomerDashboard = () => {
  const sideBarMenu = [
    { title: "Details", link: "customer" },
    { title: "Deposit", link: "deposit" },
    { title: "Transfer", link: "transfer" },
    { title: "Withdraw", link: "withdraw" },
  ];
  return (
    <>
      <DashboardWrapper>
        <SideBarWrapper>
          {sideBarMenu.map((menu, i) => (
            <li className="nav-item sidebar-link" key={i}>
              <Link to={menu.link} className="nav-link text-white p-3 mb-2">
                <i></i>
                {menu.title}
              </Link>
            </li>
          ))}
        </SideBarWrapper>
        <TopNavbarWrapper>
          <DashboardMainTitle title="customer name" />
          <div className="col-md-5"></div>
          <Signout />
        </TopNavbarWrapper>
        <SignoutModal />
      </DashboardWrapper>
      <Outlet />
    </>
  );
};

export default CustomerDashboard;
